
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        







































































































































.net-infos,
[class*='net-infos--'] {
  overflow: hidden;
  width: 100vw;
  max-width: 86rem;
  min-height: 100%;
  padding: 5rem 2rem 0;
  background: $white;

  ::v-deep {
    .cards-grid {
      padding-right: 0;
      padding-left: 0;
    }

    .cards-grid__list__item-outer {
      &:not(:first-child) {
        margin-top: $spacing * 2;
      }
    }

    .card__infos {
      padding: $spacing * 2;
    }

    .card__infos__title {
      font-size: 2.4rem;
    }

    .wysiwyg {
      font-size: 1.8rem;
    }
  }

  @include mq(m) {
    padding: $spacing * 3 $spacing * 4;
  }
}

.net-infos__category {
  @extend %fw-medium;
  @extend %text-uppercase;

  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 1px;
}

.net-infos__title {
  margin-top: $spacing;
}

.net-infos__text {
  margin-top: $spacing * 2.5;
  font-size: 1.8rem;
  line-height: 3rem;
}

.net-infos__subtitle {
  @extend %fw-bold;

  margin-top: $spacing * 2.5;
  font-family: $ff-alt;
  font-size: 2.8rem;
  line-height: 1.2;

  ::v-deep {
    strong {
      display: block;
      font-size: 3.2rem;

      @include mq(m) {
        font-size: 6.4rem;
      }
    }
  }

  @include mq(m) {
    font-size: 3.6rem;
  }
}

.net-infos__speedtest {
  @include mq($until: l) {
    display: none;
  }
}

.net-infos__slider {
  padding-bottom: 0 !important;

  ::v-deep {
    .generic-slider__slides {
      position: relative;

      @include mq($until: l) {
        text-align: center;
      }
    }

    .promo-label__arrow {
      display: none;
    }

    .flickity-button.next,
    .flickity-button.previous {
      display: inline-flex;
      margin: 2rem 0 4rem;

      @include mq(l) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .flickity-button.previous {
      margin-right: 1rem;

      @include mq(l) {
        left: 0;
        margin-right: 0;
      }
    }

    .flickity-button.next {
      @include mq(l) {
        right: 0;
      }
    }
  }

  @include mq(l) {
    display: flex;
    flex-shrink: 0;
    // overflow: hidden;
    max-width: 50rem;
    margin: 0 auto;
  }
}

.net-infos__slider__item {
  margin-right: 10rem;
}
